<template>
  <g>
    <defs>
      <linearGradient id="a" x1="0%" x2="98.048%" y1="100%" y2="0%">
        <stop offset="0%" stop-color="#83CB1B" />
        <stop offset="100%" stop-color="#1BC04A" />
      </linearGradient>
      <linearGradient id="b" x1="0%" x2="98.048%" y1="65.396%" y2="34.604%">
        <stop offset="0%" stop-color="#83CB1B" />
        <stop offset="100%" stop-color="#1BC04A" />
      </linearGradient>
    </defs>
    <g :fill="color" fill-rule="nonzero">
      <path
        fill="url(#a)"
        d="M38 20c0-9.941-8.059-18-18-18S2 10.059 2 20s8.059 18 18 18 18-8.059 18-18zm2 0c0 11.046-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0s20 8.954 20 20z"
      />
      <path
        fill="url(#b)"
        d="M18.152 24l10.115-7.587a1 1 0 0 1 1.2 1.6l-10.81 8.107a1 1 0 0 1-1.306-.093l-5.405-5.404a1 1 0 1 1 1.414-1.415L18.152 24z"
      />
    </g>
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
